function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function(){

    AOS.init();

    $.validator.addMethod("validateRecaptcha", function (value, element) {
      if (grecaptcha.getResponse() == '') {
          return false;
      } else {
          return true;
      }
    }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#container_form form').length) {
          $('#contactRgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contactRgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });


    // ToggleMenu
    $('.toggleMenu').click(function() {
        $(this).toggleClass('active');
        $('.mainMenu_items').toggleClass('active');
        $('body').toggleClass('active');
    });

    $('.itemMenu').click(function() {
        $(this).next('.subMenu').addClass('active');
    });
    
    $('.backArrow').click(function() {
        $('.subMenu').removeClass('active');
    });

    $('.searchMobile').click(function() {
        $('.searchContainerMobile').toggleClass('active');
    });

    $('.closeCrossSearch').click(function() {
        $('.searchContainerMobile').removeClass('active');
    });

    // SLIDERS HOMEPAGE
    // Slider home
    $('.owl-homeCarousel').owlCarousel({
		loop: true,
		nav: false,
		items: 1,
		dots: false,
		margin: 15,
		responsiveClass: true,
		responsive: {
			0 : {
				dots: true,
			},
			575 : {
				dots: false,
			},
			991 : {
				dots: false,
			}
		}
	});

    // Slider home actus
     $('.owl-actus').owlCarousel({
		loop: true,
		nav: false,
		items: 1,
		dots: true,
		margin: 15,
	});


    // DATES HOMEPAGE
    var heightText = $('.textHidden.active').outerHeight(true);
    $('.textHidden.active').parents('section.dates').css('padding-bottom', heightText);

    $('.pictoDatesHp').click(function() {
        $('.pictoDatesHp').removeClass('active');
        $('.textHidden').removeClass('active');
        $('.triangle').removeClass('active');
        $(this).addClass('active');
        $(this).find('.triangle').addClass('active');
        $(this).next('.textHidden').addClass('active');

        // height text hidden
        var heightText = $(this).next('.textHidden').outerHeight(true);
        $(this).parents('section.dates').css('padding-bottom', heightText);
    });

    // Slider Block texte image
    $('.owl-carouselDiapo').owlCarousel({
		loop: true,
		nav: false,
		items: 1,
		dots: false,
		margin: 15,
        autoplay: true,
        autoplayHoverPause: true,
        smartSpeed: 3000,
	});

    // En images Slider
    $('.owl-sliderImg').owlCarousel({
		loop: true,
		nav: false,
		items: 1,
		dots: false,
		margin: 15,
        responsiveClass: true,
		responsive: {
			0 : {
				dots: true,
			},
			575 : {
				dots: false,
			},
			991 : {
				dots: false,
			}
		}
	});

    $('.owlNext').click(function () {
		$('.owl-sliderImg').trigger('next.owl.carousel');
	});

	$('.owlPrev').click(function () {
		$('.owl-sliderImg').trigger('prev.owl.carousel');
	});

    $('.imgClick').click(function() {
        var nbSlide = $(this).attr('data-slide');
        $('.owl-sliderImg').trigger('to.owl.carousel', [nbSlide, 300]);

        $('body').addClass('active');
        $(this).parent().next('.imgSlider').addClass('active');
    });

    $('.imgSlider').click(function(event) {
        if($(event.target).closest('.itemImg').length == 0 && $(event.target).closest('.owlPrev').length == 0 && $(event.target).closest('.owlNext').length == 0) {
            $('body').removeClass('active');
            $('.imgSlider').removeClass('active');
        }
    });

    // Slider block slider
    $('.owl-carouselSlider').owlCarousel({
		loop: true,
		nav: false,
		items: 1,
		dots: true,
		margin: 15,
	});

    $('.owlNext').click(function () {
		$('.owl-carouselSlider').trigger('next.owl.carousel');
	});

	$('.owlPrev').click(function () {
		$('.owl-carouselSlider').trigger('prev.owl.carousel');
	});

    // Accordeon


    $('.titleArticle').click(function() {
        var heightTitle = $(this).outerHeight(true);
        var heightText = $(this).next('.containerText').find('.text').outerHeight(true);
        // $('.eachArticle').removeClass('active');
        $(this).parent().toggleClass('active');
        $(this).toggleClass('active');

        if($(this).parent().hasClass('active')) {
            $(this).parent().css('height', heightTitle + heightText);
            $(this).next('.containerText').css('height', heightText);
        } else {
            $(this).parent().css('height', heightTitle);
        }
    });

    $('.titleArticle').each(function() {
        if(!$(this).parent().hasClass('active')) {
            $(this).parent().css('height', $(this).outerHeight(true));
        }
    });

    // Search bar
    $('.pictoSearch').click(function() {
        $(this).parents('.beforeMenu').find('.search').toggleClass('active');
    });

    // Calendrier
    $('.categCalendar').click(function() {
        $(this).toggleClass('unactive');

        // console.log($(this).attr('data-categ'));
        // console.log($('.eachEvent').attr('data-categ'));

        var categID = $(this).attr('data-categ');

        

        if($(this).hasClass('unactive')) {
            $('.col-eachEvent').each(function() {
                var eventID = $(this).attr('data-categ');
                // console.log(eventID);
                if(categID == eventID) {
                    $(this).addClass('unactive');
                }
            });
        } else {
            $('.col-eachEvent').each(function() {
                var eventID = $(this).attr('data-categ');
                // console.log(eventID);
                if(categID == eventID) {
                    $(this).removeClass('unactive');
                }
            });
        }
        $('.yearsCalendar').each(function() {

            $('#' + $(this).attr('id') + ' .col-eachEvent').not('.unactive');

            if($('#' + $(this).attr('id') + ' .col-eachEvent').not('.unactive').length == 0 ) {
                $(this).css('display', 'none');
            } else {
                $(this).css('display', 'block');
            }

        });
    });

    if($('section.category').length) {
        $(window).scroll(anchorScroll);
    }
    
});

function anchorScroll() {

    // Récupérer la position du haut du menu Sticky
    var stickyTop = $('section.category').offset().top;

    // Récupèrer le bottom du menu Sticky
    var heightSticky = $('section.category').outerHeight(true);
    var stickyBottom = $('section.category').offset().top + heightSticky;

    // Récupérer le haut du content
    var contentTop = $('section.calendar').offset().top;

    // Récupérer le bottom du header
    var heightHeader = $('header').outerHeight(true);
    var headerBottom = $('header').offset().top + heightHeader;

    // Récupérer le bottom du headerpage
    var heightTopPage = $('.topPage').outerHeight(true);
    var topPageBottom = $('.topPage').offset().top + heightTopPage;

    // Récupérer le top des indispensables
    var topNeeds = $('.needsCalendar').offset().top;

    if(stickyTop <= headerBottom) {
        $('section.category').addClass('active');
    }

    if(stickyBottom <= topPageBottom) {
        $('section.category').removeClass('active');
    }

    if(topNeeds <= stickyBottom) {
        $('section.category').addClass('opacity0');
    }

    if(stickyBottom <= topNeeds) {
        $('section.category').removeClass('opacity0');
    }
}